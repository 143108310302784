import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { api } from '../../services/api';
import NumberFormat from 'react-number-format';


export function Planos() {

	const Toast = Swal.mixin({
		toast: true,
		position: 'top-end',
		showConfirmButton: false,
		timer: 4000,
		timerProgressBar: true
	});

	const [listPlanos, setPlanos] = useState<any[]>([]);

	const [parametros, setParametros] = useState<any>({
		titulo: null,
		subtitulo: null
	});

	// const [mensal, setMensal] = useState<boolean>(true);

	useEffect(() => {
		getParametros();
		getPlanos();
		// setMensal(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);



	async function getPlanos() {
		try {
			let response: any = await api.get('plano');
			response = response.data;
			if (response.erro > 0) {
				console.log(response.dados);
				Toast.fire('Falha ao buscar planos', '', 'error');
			} else {
				setPlanos(response.dados);
			}
		} catch (error) {
			console.log(error);
			Toast.fire('Falha ao buscar planos, problemas com o servidor', '', 'error');
		}
	}

	async function getParametros() {
		try {
			let response: any = await api.get('plano/parametros');
			response = response.data;
			if (response.erro > 0) {
				console.log(response.dados);
				Toast.fire('Falha ao buscar parametros planos', '', 'error');
			} else {
				setParametros(response.dados);
			}
		} catch (error) {
			console.log(error);
			Toast.fire('Falha ao buscar parâmetros planos, problemas com o servidor', '', 'error');
		}

	}


	return (
		<div className="mt-sm-n20">
			<div className="landing-curve landing-dark-color">
				<svg viewBox="15 -1 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M1 48C4.93573 47.6644 8.85984 47.3311 12.7725 47H1489.16C1493.1 47.3311 1497.04 47.6644 1501 48V47H1489.16C914.668 -1.34764 587.282 -1.61174 12.7725 47H1V48Z" fill="currentColor"></path>
				</svg>
			</div>
			<div className="py-20 landing-dark-bg">
				<div className="container">
					<div className="d-flex flex-column container pt-lg-20">
						<div className="mb-13 text-center">
							<h1 className="fs-2hx fw-bolder text-white mb-5" id="planos" data-kt-scroll-offset="{default: 100, lg: 150}">{parametros.titulo}</h1>
							<div className="text-white fw-bold fs-5">{parametros.subtitulo}</div>
						</div>
						<div className="text-center" id="kt_pricing">
							{/* <div className="nav-group landing-dark-bg d-inline-flex mb-15" data-kt-buttons="true" style={{ border: '1px dashed #FFF' }}>
								<button type="button" key="btnMensal" onClick={() => setMensal(true)} className={'btn btn-color-gray-600 btn-active btn-active-success px-6 py-3 ' + (mensal ? 'me-2 active' : '')}  >Mensal</button>
								<button type="button" key="btnAnual" onClick={() => setMensal(false)} className={'btn btn-color-gray-600 btn-active btn-active-success px-6 py-3' + (!mensal ? 'me-2 active' : '')} >Anual</button>
							</div> */}
							<div className="row flex-row " >
								{listPlanos.map(plano => (

									<div className="col-lg-3 col-md-6 mt-3" key={plano.id_plano}>

										<div className={'d-flex h-100 align-items-center ' + (plano.indicado ? 'ribbon ribbon-top ribbon-end ribbon-clip' : '')}>
											{plano.indicado ? <div className="ribbon-label text-primary bg-white">Indicado</div> : <></>}
											<div className={'w-100 d-flex flex-column flex-center rounded-3   px-10 ' + (plano.indicado ? 'bg-primary py-20' : 'bg-white py-15')}>

												<div className="mb-7 text-center">

													<h1 className={' mb-5 fw-boldest ' + (plano.indicado ? 'text-white' : 'text-dark')}>{plano.nome}</h1>
													<div className={'fw-bold mb-5 ' + (plano.indicado ? 'text-white opacity-75' : 'text-gray-400')}>{plano.descricao}</div>
													<div className="text-center">
														<span className={'mb-2 ' + (plano.indicado ? 'text-white ' : 'text-primary ')}>R$</span>
														<NumberFormat
															value={plano.valor_mes}
															className={'fs-3x fw-bolder ' + (plano.indicado ? 'text-white' : 'text-primary')}
															displayType={'text'}
															decimalScale={2}
															decimalSeparator=','
															thousandSeparator={'.'}
															isNumericString={true}
															prefix={' '}
														/>
														<span className={'fs-7 fw-bold opacity-50 ' + (plano.indicado ? 'text-white' : '')} >/ Mês</span>
													</div>
												</div>
												<div className="w-100 mb-10">
													{plano.lista_funcionalidade.map((funcionalidade: any) => (
														<div className="d-flex flex-stack mb-5" key={funcionalidade.id_funcionalidade}>
															<span className={'fw-bold fs-6  text-start pe-3 ' + (plano.indicado ? 'text-white opacity-75' : 'text-gray-800')}>{funcionalidade.descricao}</span>
															{funcionalidade.ativo ?
																<span className={'svg-icon svg-icon-1 ' + (plano.indicado ? 'svg-icon-white' : 'svg-icon-success')} >
																	<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
																		<path d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z" fill="#000000" fillRule="nonzero" />
																	</svg>
																</span> :
																<span className={'svg-icon svg-icon-1 ' + (plano.indicado ? 'svg-icon-white' : '')}>
																	<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																		<circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
																		<path d="M12.0355339,10.6213203 L14.863961,7.79289322 C15.2544853,7.40236893 15.8876503,7.40236893 16.2781746,7.79289322 C16.6686989,8.18341751 16.6686989,8.81658249 16.2781746,9.20710678 L13.4497475,12.0355339 L16.2781746,14.863961 C16.6686989,15.2544853 16.6686989,15.8876503 16.2781746,16.2781746 C15.8876503,16.6686989 15.2544853,16.6686989 14.863961,16.2781746 L12.0355339,13.4497475 L9.20710678,16.2781746 C8.81658249,16.6686989 8.18341751,16.6686989 7.79289322,16.2781746 C7.40236893,15.8876503 7.40236893,15.2544853 7.79289322,14.863961 L10.6213203,12.0355339 L7.79289322,9.20710678 C7.40236893,8.81658249 7.40236893,8.18341751 7.79289322,7.79289322 C8.18341751,7.40236893 8.81658249,7.40236893 9.20710678,7.79289322 L12.0355339,10.6213203 Z" fill="#000000" />
																	</svg>
																</span>
															}
														</div>
													))}
													<div className="d-flex flex-stack mb-5">
														<span className={'fw-boldest fs-6  text-start pe-3 ' + (plano.indicado ? 'text-white opacity-75' : 'text-gray-800')}>Usuários</span>
														<span className={'fw-boldest ' + (plano.indicado ? 'text-white' : '')}>{plano.quantidade_usuario}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="landing-curve landing-dark-color">
				<svg viewBox="15 12 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z" fill="currentColor"></path>
				</svg>
			</div>
		</div >
	)
}