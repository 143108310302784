import React, { useEffect, useState } from 'react';
import logoLight from '../../assets/logo/logo_light.png'
import logoDark from '../../assets/logo/logo_dark.png'
import Typed from 'react-typed';
import { api } from '../../services/api';
import Swal from 'sweetalert2'

export function Header() {

	const Toast = Swal.mixin({
		toast: true,
		position: 'top-end',
		showConfirmButton: false,
		timer: 4000,
		timerProgressBar: true
	});

	const [frases, setFrases] = useState<any[]>([]);

	useEffect(() => {
		getFrases();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function getFrases() {
		let response: any = await api.get('header/frases');
		if (response.status === 200) {
			response = response.data
			const frasesBaixadas = [];
			if (response.erro > 0) {
				console.log(response.dados);
				Toast.fire(response?.dados || 'Falha ao buscar dados do cabeçalho', '', 'error');
			} else {
				for (const frase of response.dados) {
					frasesBaixadas.push(frase.frase);
				}
				setFrases(frasesBaixadas);
			}
		} else {
			response = response.data
			console.log(response.dados);
			Toast.fire(response?.dados || 'Falha ao buscar dados do cabeçalho', '', 'error');
		}

	}

	return (
		// <!--begin::Header Section-->
		<div className="mb-0" id="home">
			<div className="item1 bgi-no-repeat bgi-size-contain bgi-position-x-center bgi-position-y-bottom landing-dark-bg"
				style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + 'assets/metronic/media/svg/illustrations/landing.svg' }}>

				<div className="landing-header" data-kt-sticky="true" data-kt-sticky-name="landing-header" data-kt-sticky-offset="{default: '200px', lg: '300px'}">
					<div className="container">
						<div className="d-flex align-items-center justify-content-between">
							<div className="d-flex align-items-center flex-equal">
								<button className="btn btn-icon btn-active-color-primary me-3 d-flex d-lg-none" id="kt_landing_menu_toggle">
									<span className="svg-icon svg-icon-2hx">
										<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
											<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
												<rect x="0" y="0" width="24" height="24" />
												<rect fill="#000000" x="4" y="5" width="16" height="3" rx="1.5" />
												<path d="M5.5,15 L18.5,15 C19.3284271,15 20,15.6715729 20,16.5 C20,17.3284271 19.3284271,18 18.5,18 L5.5,18 C4.67157288,18 4,17.3284271 4,16.5 C4,15.6715729 4.67157288,15 5.5,15 Z M5.5,10 L18.5,10 C19.3284271,10 20,10.6715729 20,11.5 C20,12.3284271 19.3284271,13 18.5,13 L5.5,13 C4.67157288,13 4,12.3284271 4,11.5 C4,10.6715729 4.67157288,10 5.5,10 Z" fill="#000000" opacity="0.3" />
											</g>
										</svg>
									</span>
								</button>
								<a href="https://sidecloud.com.br">
									<img alt="Trez" src={logoLight} className="logo-default h-25px h-lg-30px" />
									<img alt="Trez" src={logoDark} className="logo-sticky  h-20px h-lg-25px" />
								</a>
							</div>
							<div className="d-lg-block" id="kt_header_nav_wrapper">
								<div className="d-lg-block p-5 p-lg-0" data-kt-drawer="true" data-kt-drawer-name="landing-menu" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="200px" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_landing_menu_toggle" data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: 'kt_body', lg: '#kt_header_nav_wrapper'}">

									<div className="menu menu-column flex-nowrap menu-rounded menu-lg-row menu-title-gray-500 menu-state-title-primary nav nav-flush fs-5 fw-bold" id="kt_landing_menu">
										<div className="menu-item">
											<a className="menu-link nav-link active py-3 px-4 px-xxl-6" href="#kt_body"
												data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">Side</a>
										</div>

										<div className="menu-item">
											<a className="menu-link nav-link py-3 px-4 px-xxl-6" href="#modulos" data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">Módulos</a>
										</div>

										<div className="menu-item">
											<a className="menu-link nav-link py-3 px-4 px-xxl-6" href="#planos" data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true">Planos</a>
										</div>

									</div>

								</div>
							</div>
							<div className="flex-equal text-end ms-1">
								<div className="d-none d-md-block">
									<a className="btn btn-success   " href="https://app.sidecloud.com.br?cadastrar-empresa=1/" style={{ marginRight: '10px' }}>Experimentar</a>
									<a href="https://app.sidecloud.com.br/" className="btn btn-active-light-dark btn-text-gray-500 ">Já sou cliente</a>
								</div>

								<div className="btn-group d-block d-md-none">
									<a href="https://app.sidecloud.com.br?cadastrar-empresa=1/" type="button" className="btn btn-success">Experimentar</a>
									<a href="https://app.sidecloud.com.br/" title="Já sou cliente" type="button" className="btn btn-light-success font-weight-bold">
										<i className="fas fa-sign-in-alt"></i></a>

								</div>


							</div>
						</div>
					</div>
				</div>
				<div className="d-flex flex-column flex-center w-100 min-h-350px min-h-lg-500px px-9">
					<div className="text-center mb-5 mb-lg-10 py-10 py-lg-20">

						{frases.length > 0 ? <Typed className="text-white lh-base fw-bolder fs-2x fs-lg-3x mb-15"
							strings={frases}
							typeSpeed={40}
							backSpeed={40}
							loop >
						</Typed> : <br />}
						<br />

						<a href="https://app.sidecloud.com.br?cadastrar-empresa=1/" className="btn mt-10 btn-light-primary">Cadastre-se</a>
					</div>


				</div>
			</div>
			<div className="landing-curve landing-dark-color mb-10 mb-lg-20">
				<svg viewBox="15 12 1470 48" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M0 11C3.93573 11.3356 7.85984 11.6689 11.7725 12H1488.16C1492.1 11.6689 1496.04 11.3356 1500 11V12H1488.16C913.668 60.3476 586.282 60.6117 11.7725 12H0V11Z" fill="currentColor"></path>
				</svg>
			</div>
		</div>
	)
}